import React, {useState} from "react";
import Layout from "@components/layout"
import parse from "html-react-parser"

import { archiveGrayOut } from "@components/archives-grayOut.js"; // 終了タググレーアウト処理

// 言語用Jsonファイル
import langEn from "@content/locales/en/blog-archive.json";
import langJa from "@content/locales/ja/blog-archive.json";

// 共通Link用ファイル
import { LinkSetting } from "@components/link-setting.js";

import { SeoContents } from "@components/seo-data.js" // SEO,meta情報設定
import { ChangeContents } from "@components/change-contents.js"; // 本文HTML操作

// Import SVG
import challengeSVG1 from '@images/1man_img01.svg';
import challengeSVG2 from '@images/1man_icon01.svg';
import challengeSVG3 from '@images/1man_icon02.svg';
import challengeSVG4 from '@images/1man_icon03.svg';
import Rogo from "@images/rogo.svg"

import { GetLeadresBoardData } from "@components/api-getLeadresBoardData.js"; // 終了タググレーアウト処理

// ユニークの変数名
const CampaignPostArchive = ( data ) => {

  const postData = data.pageContext;
  
  // 言語設定
  const setLang = postData.lang;
  const selectType = postData.category;
  let postLang, langUrl, categUri, setNode;
  setLang === 'en'? postLang = langEn: postLang = langJa;
  setLang === 'en'? langUrl = '/': langUrl = '/' + setLang + '/';
  categUri = postData.uri;
  setNode = postData.data;

  // 本文記事
  const postContent = ChangeContents(postData.firstData.node.content, setLang);

  // Seo情報設定
  const seoTag = SeoContents( 
    categUri, setLang, 
    postLang[ selectType + "-page-title" ], 
    postLang[ selectType + "-page-description"],
    postLang[ selectType + "-page-keywords"],
    "archive", postData.num
  );

  // post設定
  const posts = setNode ;
  
  // 最終ページnext消去
  let leadUri = langUrl + categUri + "/" ;
  const previousPagePath = leadUri + (postData.num - 1) + "/";
  const nextPagePath = leadUri + (postData.num + 1) + "/";
  let prevIndicate, nextIndicate;
  (postData.num - 1) === 0? prevIndicate = 'hidden': prevIndicate = '';
  postData.num === postData.maxnum? nextIndicate = 'hidden': nextIndicate = '';
  
  // リンク設定
  const signPath1 = LinkSetting( setLang ).Register;
  const signPath2 = LinkSetting( setLang ).Login;

  // 終了タググレーアウト処理
  let archiveList = archiveGrayOut (posts, leadUri, postLang);

  // reader board設定
  const num = 5; // 表示するデータ数
  const boardArray = GetLeadresBoardData(num);
  const [pageNum, setPage] = useState(1);
  const handleClickIncrease = () => {setPage(pageNum + 1);};
  const handleClickDecrease = () => {setPage(pageNum - 1);};
  
  // チャレンジicon設定
  const challengeArray = [
    {value: '100', divclass:'h-[10px]', p1class: 'lg:top-[-40%] top-[-35%]', p2class: 'lg:top-[-2%] top-[-3%]', img: challengeSVG2, alt: ''},
    {value: '50', divclass:'h-[5px]' , p1class: 'lg:top-[-24%] top-[-13%]', p2class: 'lg:top-[4%] top-[7%]', img: challengeSVG3, alt: ''},
    {value: '20', divclass:'h-[5px]' , p1class: 'lg:top-[-24%] top-[-16%]', p2class: 'lg:top-[6%] top-[7%]', img: challengeSVG4, alt: ''},
  ]
  
  return (
    <>
      {seoTag} 
      <Layout isHomePage>
        <div className="FV pt-5 pb-8 bg-fixed" langType={setLang}>
          <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2 ">
            <div className=" text-left h1_filed">
              <h1 className="md:text-[60px] text-[35px] font-semibold">{postLang[selectType + "-title"]}</h1>
              <div className="text-base cat_btn">
                <p>{postLang[selectType + "-txt1"]}{postLang[selectType + "-txt2"]}
                  <br></br>{postLang[selectType + "-txt5"]}
                  <a href={postLang[selectType + "-link1"]} className="underline forcus:no-underline">{postLang[selectType + "-txt6"]}</a>{postLang[selectType + "-txt7"]}
                </p>
              </div>
            </div>
          </div>
        </div>
        
        {/* チャレンジのページのみ表示 ------------------ */}
        {selectType === 'tradecampaign'? 
         <div>
         <div className="bg-gradient-to-t from-[#E6E6E6] to-[#fff] ">
           <div className="max-w-xl mx-auto lg:max-w-5xl lg:mt-10 mt-5 lg:relative lg:pb-[44%] pb-[10%] lg:px-0 px-5">
             <div className="lg:absolute top-0 left-0 z-20 lg:pl-5">
               <p className="text-[#047DFC] lg:text-2xl text-2xl mb-2 font-bold">{postLang["challenge-top-text1"]}</p>
               <h2 className="tracking-wide font-semibold lg:text-5xl text-4xl lg:text-[40px] mb-10 lg:leading-[1.4]">{postLang["challenge-top-text2"]}
                 <br></br>{postLang["challenge-top-text3"]}
               </h2>

               {/* h3 section */}
               {(() => { const h3item=[]; for(let i = 0; i < 5; i++) { h3item.push(
                 <h3 className="font-semibold pl-2.5 mb-6 text-base md:text-[24px] charange_mark_blue">
                   <span>{postLang[`challenge-sub${i + 1}-title`]}</span>
                 </h3>
               )} return(h3item)
               })()}
               
               <a href={signPath2} className="charange_btn_login cont_regi01 rounded block lg:max-w-xs text-center text-white font-bold px-16 my-2 bg-[#047DFC] hover:scale-[1.05] pt-[18px] pb-[20px] duration-300 !mt-[10%]">{postLang["open3"]}</a>
               <a href={signPath1} className="charange_btn_regi cont_regi01 rounded block lg:max-w-xs text-center text-white font-bold px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300 mt-5">{postLang["open"]}</a>
             </div>
             <p className="lg:absolute bottom-[32%] lg:right-[-13%] right-0 max-w-[700px] ">
               <img src={challengeSVG1} alt="１万円チャレンジイメージ画像｜Milton Markets" />
             </p>
             <div className="lg:absolute bottom-[-25%]"></div>
           </div>
         </div>

         <div className="flex justify-between lg:mt-[-50px] mt-[-25px] max-w-[980px] mx-auto lg:px-0 px-5">
           {challengeArray.map((result, index) =>
             <div className="relative text-center bg-white w-[30%] lg:px-10 p-5  rounded">
               <div className={`${result.divclass}`}>
                 <p className={`${result.p1class} absolute left-[50%] lg:ml-[-40px] ml-[-30px] lg:max-w-[80px] max-w-[60px]`}>
                   <img src={result.img} alt={result.alt}></img>
                 </p>
                 <p className={`${result.p2class} absolute left-[50%] lg:ml-[-4%] md:ml-[-4%] ml-[-9%] text-white font-bold lg:text-sm text-[12px]`}>{postLang[`challenge-icon-text${index+1}`]}</p>
               </div>
               <p className="lg:text-6xl text-3xl font-bold text-[#067CFA]">{result.value}</p>
               <p className="text-[16px] text-[#067CFA] font-bold pt-[5px]">{postLang["challenge-icon-unit"]}</p>
             </div>
           )}
         </div>

            {/* Leader board */}
            <div className={`max-w-xl mx-auto md:pt-0 pt-6 md:max-w-5xl px-6`}>
              <div className="flex mx-auto max-w-[800px] justify-center items-center">
                <p className="border-b-[1px] md:w-[100px] w-[40px] md:pt-10 pt-5 mx-4 border-b-[#707070]"></p>
                <h2 className="mb-2 text-center pt-[5%] text-[22px] md:text-[28px]">{postLang[`tradecampaign-txt4`]}</h2>
                <p className="border-b-[1px] md:w-[100px] w-[40px] md:pt-10 pt-5 mx-4 border-b-[#707070]"></p>
              </div>
              <div className="bg-[#FAFAFA] w-full pb-[2px] mt-[2%] md:mb-0 mb-[10%] rounded-md">
                <p className="mx-10  pt-10"><img className="w-[90px] " src={ Rogo } alt=""></img></p>
                  <h3 className="mx-0 mt-2 pb-5 border-b-[1px] border-[#DDDDDD] px-10 pb-5 font-semibold text-2xl">Leader board</h3>
                  {boardArray !== null && boardArray.length !== 0?
                  <>
                    <table className="mx-[5%] w-[90%] md:text-base text-[13px]">
                      <tr className="mx-10 mt-10  border-b-[1px] border-[#DDDDDD] text-[#818181]">
                        <td className="py-5 md:w-[25%] w-[40%]">{postLang[`tradecampaign-txt5`]}</td>
                        <td className="md:w-[18%] w-[23%]">{postLang[`tradecampaign-txt6`]}</td>
                        <td className="">{postLang[`tradecampaign-txt7`]}</td>
                      </tr>
                      {boardArray[pageNum - 1].map((result) =>
                        <tr className="mx-10 mt-10  border-b-[1px] border-[#DDDDDD] ">
                          <td className="py-5 h-[65px]">{result.nickname}</td><td>{result.country}</td><td>{result.performance}</td>
                        </tr>
                      )}  
                    </table>
                    <div className="flex justify-end mx-[5%] w-[90%] md:text-base text-[13px] pb-6">1 - {boardArray.length} of {pageNum}
                      <div className='ml-[16px] font-bold'>
                        {pageNum === 1? <span className='text-[rgb(129_129_129)]'>＜</span>:<button className='' onClick={handleClickDecrease}>＜</button>}
                      </div>
                      <div className='ml-[16px] font-bold'>
                        {pageNum === boardArray.length? <span className='text-[rgb(129_129_129)]'>＞</span>:<button className='' onClick={handleClickIncrease}>＞</button>}
                      </div>
                    </div>
                  </>
                  :
                  
                  <>
                    <table className="mx-[5%] w-[90%] md:text-base text-[13px]">
                      <tr className="mx-10 mt-10  border-b-[1px] border-[#DDDDDD] text-[#818181]">
                        <td className="py-5 md:w-[25%] w-[40%]">ニックネーム</td><td className="md:w-[18%] w-[23%]">国</td><td className="">パフォーマンス</td>
                      </tr>
                      <tr className="mx-10 mt-10  border-b-[1px] border-[#DDDDDD] text-[#818181]">
                      {boardArray === null?
                        <td className="py-5">Loading...</td>
                        :
                        <td className="py-5">no data</td>
                      }
                      </tr>
                    </table>
                  </>
                  }
              </div>
            </div>
            
            {/* FXチャレンジ本文 */}
            <div className="blog-post lg:px-0 px-6">
              <div className=" md:my-0 md:mx-auto" style={{ paddingTop: 24, paddingBottom: 24, maxWidth: 976}}>
                <div className="text-left md:mt-11 mt-5 h-1/2 max-w-xl mb-0">
                  <h2 className="tracking-wide text-[26px] font-semibold mb-10 md:text-[40px]" itemProp="headline">
                    {parse(postData.firstData.node.title)}  
                  </h2>
                </div>
                {!!postData.firstData.node.content && (                  
                  <section itemProp="articleBody">{postContent}</section>
                )}
              </div>
            </div>
          </div>
          :
          <></>
        }
        {/* ------------------------------------------ */}

        {/* <div className="Feature02 mt-10 bg-fixed mb-10">
          <div className="py-14 py-14 md:py-10 md:py-10">
            <div className="md:mx-auto max-w-5xl flex justify-center">
              <a href={signPath2} className="cont_regi01 rounded block md:max-w-xs text-center text-white font-bold  px-16 my-2 bg-[#FE0000] hover:scale-[1.05] pt-[18px] pb-[20px] duration-300">{postLang["open3"]}</a>
            </div>
            <div className="md:mx-auto max-w-5xl flex justify-center">
              <a href={signPath1} className="rounded block md:max-w-xs mx-5 text-center text-white font-bold px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300">{postLang["open"]}</a>
            </div>
          </div>
        </div>*/} 

       {/*  <div className="max-w-xl mx-auto  md:max-w-5xl  md:mt-10 mt-5  px-5">
          <div className="text-left md:mt-12 mt-8 h-1/2 mb-10 ">
            <h2 className="tracking-wide font-semibold text-[26px] md:text-[40px]">{postLang[selectType + "-txt3"]}</h2>
          </div>
        </div>

        <ol style={{ listStyle: `none` }} className="md:max-w-5xl px-6 mx-auto ">
          <div className="mx-auto md:max-w-5xl pb-4 md:flex max-w-xl md:flex-wrap md:justify-start items-center md:max-w-5xl">{archiveList} </div>
        </ol>*/} 
        
        <div className="Feature pt-5 mt-10 bg-fixed">
          <div className="flex px-5 text-[11px] md:text-sm">
            <div className={`leftPagePath text-center w-1/2`}>{prevIndicate !== 'hidden'? 
              <a href={previousPagePath}>＜ {postLang["previousPage"]}</a>: '' }
            </div>
            <div className={`rightPagePath text-center w-1/2`}>{nextIndicate !== 'hidden'? 
              <a href={nextPagePath}>{postLang["nextPage"]} ＞</a>: '' }
            </div>
          </div>
          <div className="py-14 py-14 md:py-10 md:py-10">
            <div className="md:mx-auto max-w-5xl flex justify-center">
              <a href={signPath1} className="rounded block md:max-w-xs mx-5 text-center text-white font-bold px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300">{postLang["open"]}</a>
            </div>
          </div>
        </div>

      </Layout>
    </>
  )
}
export default CampaignPostArchive
